<template>
  <b-container fluid class="login-2022 mx-auto">
    <b-row class="vh-100" align-v="center">
      <b-col cols="4" class="l-left">
        <b-row class="flex-column h-100 ml-4 mr-5">
          <b-col cols="2" class="d-flex align-items-center">
            <div class="logo-black"> </div>
          </b-col>
          <b-col>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" lg="8" class="l-right">
        <b-row class="h-100 align-items-center" align-v="center">
          <div class="mx-auto login-action text-center">
            <div class="ee-logo mx-auto"> </div>
            <h1 class="my-3">Forgot Your Password</h1>
            <p class="text-center text-muted small p-0 m-0 pb-2 px-1">Minimum of 8 characters, 1 upper case, 1 lower case, 1 number.</p>
            <b-form @submit="setPassword" v-on:submit.prevent class="credentials-form" :class="{'larkin-form-dirty': $v.input.$dirty}">
              <div class="invalid-feedback d-block" v-if="formError()">
                {{formErrorMessage()}}
              </div>

              <b-form-group label-for="newPassword" class="my-2" :class="getInvalidFeedbackClass($v.input.newPassword.$invalid)">
                <b-input-group>
                  <b-form-input v-model="$v.input.newPassword.$model" type="password" placeholder="New Password" id="newPassword" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="$v.input.$invalid">
                  <span v-if="!$v.input.newPassword.required && $v.input.$model.newPassword">New password is required</span>
                  <span v-else-if="!$v.input.newPassword.invalidNewPassword && $v.input.$model.newPassword">New password not valid</span>
                  <span v-else-if="$v.input.newPassword.sameAsCurrentPassword && $v.input.$model.newPassword">New password cannot match current password</span>
                </div>
              </b-form-group>

              <b-form-group label-for="passwordCon  firm" class="my-2" :class="getInvalidFeedbackClass($v.input.passwordConfirm.$invalid)">
                <b-input-group>
                  <b-form-input v-model="$v.input.passwordConfirm.$model" type="password" placeholder="Verify New Password" id="passwordConfirm" @change="clearErrors()"></b-form-input>
                </b-input-group>
                <div class="invalid-feedback d-block" v-if="$v.input.$invalid">
                  <span v-if="!$v.input.passwordConfirm.required && $v.input.$model.passwordConfirm">Confirm password is required</span>
                  <span v-else-if="!$v.input.passwordConfirm.notSameAsNewPassword && $v.input.$model.passwordConfirm">Must match new password</span>
                  <span v-else-if="!$v.input.newPassword.sameAsCurrentPassword && $v.input.$model.passwordConfirm">New password cannot match current password</span>
                </div>
              </b-form-group>

              <b-btn :disabled="$v.input.$invalid" type="submit" class="btn-larkin-yellow btn-full">
                <span class="login-text">Reset Password</span>
                <div v-if="isSubmitting" v-bind:class="{'local-spinner-border':isSubmitting, 'local-spinner-spacer':!isSubmitting}"/>
              </b-btn>
            </b-form>
          </div>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import ApiProperties from '../../../src/main/resources/application'
  import FormMixin from '@/mixins/formMixin'
  import { required, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'setPassword',
    mixins: [FormMixin],
    data: function() {
      return {
        isSubmitting: false,
        input: {
          newPassword: '',
          passwordConfirm: ''
        }
      }
    },
    validations: {
      input: {
        newPassword: {
          required,
          invalidNewPassword(newPassword) {
            return this.$root.validation.password.regex.test(newPassword);
          }
        },
        passwordConfirm: {
          required,
          notSameAsNewPassword: sameAs(function() { return this.input.newPassword })
        }
      }
    },
    methods: {
      setPassword() {
        this.isSubmitting = !this.$v.$invalid;

        if(this.isSubmitting) {
          this.submit('post', ApiProperties.api.updatePassword, {}, this.input, (response) => {
            this.isSubmitting = false;
            this.$root.updateAuthStatus(response.data);
            this.$router.push({name: 'home'});
          },() => {
            this.isSubmitting = false;
          })
        }
      }
    }
  }
</script>
<style lang="scss">
  @import '../scss/login-pages';
</style>